import React, { Suspense } from 'react';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { createContext, useContext } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const FallbackContext = createContext();
const loaderDelay = 300;

export function FallbackProvider({ children }) {
    const [fallback, setFallback] = useState(null);

    const updateFallback = useCallback((fallback) => {
        setFallback(() => fallback);
    }, []);

    const renderChildren = useMemo(() => children, [children]);

    return (
        <FallbackContext.Provider value={updateFallback}>
            <Suspense fallback={<>{fallback}<Loader /></>}>{renderChildren}</Suspense>
        </FallbackContext.Provider>
    );
}

export default function Fallback({ children }) {
    const fallback = useFallback();

    const render = useMemo(() => <>{children}</>, [children]);

    useEffect(() => { fallback(render); }, [fallback, render]);

    return render;
}

export function useFallback() {
    const updateFallback = useContext(FallbackContext);

    const fallback = useCallback(component => {
        updateFallback(component === undefined ? null : component);
    }, [updateFallback]);

    return fallback;
}

function Loader() {
    const [open, setOpen] = useState(false);

    useEffect(() => { setTimeout(() => setOpen(true), loaderDelay); }, []);

    return (
        <Backdrop open={open} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <CircularProgress />
        </Backdrop>
    );
}
