import * as jose from 'jose';

/**
 * Verify the given `token` is valid and then return its payload
 *
 * @param {string} token
 * @param {string} jwkURL
 * @param {Object} opts
 * @returns {Promise<Object>}
 */
export async function verifyToken(token, jwkURL, opts = {}) {
    const jwks = jose.createRemoteJWKSet(new URL(jwkURL));
    const { payload } = await jose.jwtVerify(token, jwks, opts);
    return payload;
}
