import React from 'react';
import { Grid, Typography, CircularProgress } from '@mui/material';

export default function Loading({ label }) {
    return (
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" sx={{ minHeight: '50vh' }}>
            <Grid item xs={3} align="center">
                <Typography>{label}</Typography>
                <CircularProgress />
            </Grid>
        </Grid>
    );
}
