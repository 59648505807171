import * as jwt from './jwt.js';
import * as oidc from './oidc.js';
import * as string from './string.js';
import * as cognito from './cognito.js';
import * as browser from './browser.js';
import memoryCache from './memory-cache.js';

export const clientId = 'vtd0sgh0l1g7kiiaj27go7q1h';
export const pkceCookieName = 'pkce';
export const pkceCookieTTL = 60 * 10; // 10 minutes in seconds
export const refreshTokenKey = 'refresh_token';
export const refreshTokenTTL = 60 * 60 * 24 * 10 * 1000; // 10 days in milliseconds

const cache = memoryCache();

/**
 * Check if the user is authenticated. Either they already have a refresh token
 * that we can use to attempt to fetch a new set of tokens, or they are
 * returning from the OpenID Provider with an authorization code. If neither of
 * those are the case we need to redirect them to the OpenID Provider to obtain
 * and authorization code.
 *
 * @param {Function} setTokens
 * @param {Function} setNotAuthorized
 * @returns {Promise<boolean>} - TRUE if the user is authenticated
 */
export async function checkAuthentication(setTokens, setNotAuthorized) {
    try {
        const authCode = getAuthorizationCode();
        const codeVerifier = browser.getCookie(pkceCookieName);

        if (!authCode || !codeVerifier) {
            await performAuthentication();
            return false;
        }

        const tokenURI = cognito.tokenURI();
        const tokens = await oidc.fetchTokensFromAuthorizationCode(clientId, tokenURI, authCode, codeVerifier);
        setTokens(await handleTokens(tokens, setTokens, setNotAuthorized));

        const { redirect } = getState() || {};
        if (redirect) {
            browser.setPath(redirect);
        }

        return true;
    } catch( err ) {
        console.error('error checking authentication', err);
        setNotAuthorized(true);
        return false;
    }
}

/**
 * Attempt to revoke the user's refresh token and then redirect them to the
 * OpenID Provider's logout endpoint.
 *
 * @returns {Promise<undefined>}
 */
export async function logout() {
    await revokeRefreshToken();
    cache.del(refreshTokenKey);
    window.location.assign(cognito.logoutURL(clientId, browser.origin()));
}

/**
 * We need to attempt to re-authenticate the user with a refresh token.
 *
 * @param {Function} setTokens
 * @param {Function} setNotAuthorized
 * @returns {Promise<undefined>} - TRUE if the user is authenticated
 * @throws {Promise<Error>}
 */
async function handleRefresh(setTokens, setNotAuthorized) {
    try {
        const refreshToken = getRefreshToken();
        if (!refreshToken) {
            await performAuthentication();
            throw new Error('no refresh token available');
        }

        const tokenURI = cognito.tokenURI();
        const tokens = await oidc.fetchTokensFromRefreshToken(clientId, tokenURI, refreshToken);
        if (!tokens) {
            throw new Error('could not get new tokens');
        }

        setTokens(await handleTokens(tokens, setTokens, setNotAuthorized));
    } catch (err) {
        console.error('error refreshing tokens', err);
        setNotAuthorized(true);
    }
}

/**
 * Now that we have some tokens we need to capture them.
 *
 * We also want to schedule a token refresh based on when the new tokens will
 * expire. This way users will not experience any interuptions while they are
 * using the UI.
 *
 * If there are any issue with the tokens we received such as they are invalid
 * or the user does not belong to the group(s) we need them to be in, we will
 * indicate the user is not authorized.
 *
 * @param {Object} tokens
 * @param {Function} setTokens
 * @param {Function} setNotAuthorized
 * @returns {Promise<Object>}
 * @throws {Promise<Error>}
 */
async function handleTokens(tokens, setTokens, setNotAuthorized) {
    const payload = await jwt.verifyToken(tokens.id_token, cognito.jwkUrl, {
        audience: clientId,
        issuer: cognito.issuer,
    });

    if (!isAdministrator(payload)) {
        throw new Error('user is not administrator');
    }

    if (tokens.refresh_token) {
        cache.set(refreshTokenKey, tokens.refresh_token, {
            ttl:refreshTokenTTL,
            onExpire: performAuthentication.bind(null),
        });
    }

    const tokenTimeout = (tokens.expires_in - 5) * 1000;
    setTimeout(handleRefresh.bind(null, setTokens, setNotAuthorized), tokenTimeout);

    return {
        accessToken: tokens.access_token,
        IDToken: tokens.id_token,
    };
}

/**
 * Attempt to revoke the user's current refresh token.
 *
 * @returns {Promise<undefined>}
 */
async function revokeRefreshToken() {
    const refreshToken = getRefreshToken();
    if (!refreshToken) return;
    await cognito.revokeToken(clientId, refreshToken);
}

/**
 * Redirect the user to the authorization endpoint to obtain an authorization
 * code which can be exchanged for tokens.
 *
 * @returns {Promise<undefined>}
 */
async function performAuthentication() {
    const scopes = [ 'openid' ];
    const state = { redirect: window.location.pathname };
    const codeVerifier = getCodeVerifier();
    const redirectURI = browser.origin()
    const authorizeURI = cognito.authorizeURI();
    const authorizeURL = await oidc.authorizeURL(clientId, authorizeURI, redirectURI, codeVerifier, scopes, state);
    window.location.assign( authorizeURL );
}

/**
 * Check if the given token payload belongs to a user who is an administrator.
 *
 * @param {Object} payload
 * @returns {boolean}
 */
function isAdministrator(payload) {
    return cognito.tokenPayloadContainsGroup('administrators', payload);
}

/**
 * Obtain a code verifier value to use as a Proof Key for Code Exchange (PKCE)
 *
 * @returns {string}
 */
function getCodeVerifier() {
    const codeVerifier = oidc.generateCodeVerifier();
    browser.setCookie(pkceCookieName, codeVerifier, pkceCookieTTL);
    return codeVerifier;
}

/**
 * Get the authorization code out of the URL query string
 *
 * @returns {boolean|string}
 */
function getAuthorizationCode() {
    const code = browser.getQueryParam('code');
    if (!cognito.isValidAuthorizationCode(code)) return false;
    browser.removeQueryParam('code');
    return code;
}

/**
 * Get the user's current refresh token
 *
 * @returns {string}
 */
function getRefreshToken() {
    return cache.get(refreshTokenKey);
}

/**
 * Get the state out of the URL query string
 *
 * @returns {string|null}
 */
function getState() {
    try {
        const base64 = browser.getQueryParam('state')
        if (!base64) return null;
        const state = JSON.parse(string.base64URLDecode(base64));
        browser.removeQueryParam('state');
        return state;
    } catch ( error ) {
        console.error('error extracting state from URL', error);
        return null;
    }
}
