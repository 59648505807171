import React, { lazy } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Authentication from './Authentication.js';
import AppFrame from './AppFrame.js';
import { FallbackProvider } from './Fallback.js';
import NotFound from './NotFound.js';

export default function App() {
    const Dashboard = lazy(() => import('./Dashboard.js'));
    const Users = lazy(() => import('./Users.js'));
    const Transactions = lazy(() => import('./Transactions.js'));
    const Surveys = lazy(() => import('./Surveys.js'));
    const Games = lazy(() => import('./Games.js'));
    const Leagues = lazy(() => import('./Leagues.js'));
    const LeagueGames = lazy(() => import('./LeagueGames.js'));
    const Referees = lazy(() => import('./Referees.js'));
    const Reports = lazy(() => import('./Reports.js'));
    const Newsletters = lazy(() => import('./Newsletters.js'));

    const UserAccount = lazy(() => import('./user/Account.js'));
    const UserParity = lazy(() => import('./user/Parity.js'));
    const UserActivity = lazy(() => import('./user/Activity.js'));
    const UserGames = lazy(() => import('./user/Games.js'));
    const UserTransactions = lazy(() => import('./user/Transactions.js'));
    const UserGameplayNotes = lazy(() => import('./user/GameplayNotes.js'));
    const UserSurvey = lazy(() => import('./user/Survey.js'));
    const UserEvaluations = lazy(() => import('./user/Evaluations.js'));
    const UserRoles = lazy(() => import('./user/Roles.js'));
    const UserLeague = lazy(() => import('./user/League.js'));

    return (
        <ThemeProvider theme={theme()}>
            <CssBaseline />
            <Authentication>
                <BrowserRouter>
                    <AppFrame>
                        <FallbackProvider>
                            <Routes>
                                <Route index element={<Dashboard />} />
                                <Route path="users">
                                    <Route index element={<Users />} />
                                    <Route path=":id">
                                        <Route index element={<UserAccount />} />
                                        <Route path="parity" element={<UserParity />} />
                                        <Route path="activity" element={<UserActivity />} />
                                        <Route path="games" element={<UserGames />} />
                                        <Route path="transactions" element={<UserTransactions />} />
                                        <Route path="gameplay-notes" element={<UserGameplayNotes />} />
                                        <Route path="survey" element={<UserSurvey />} />
                                        <Route path="evaluations" element={<UserEvaluations />} />
                                        <Route path="roles" element={<UserRoles />} />
                                        <Route path="league" element={<UserLeague />} />
                                    </Route>
                                </Route>
                                <Route path="transactions" element={<Transactions />} />
                                <Route path="surveys" element={<Surveys />} />
                                <Route path="games" element={<Games />} />
                                <Route path="leagues" element={<Leagues />} />
                                <Route path="leagues-games" element={<LeagueGames />} />
                                <Route path="referees" element={<Referees />} />
                                <Route path="reports" element={<Reports />} />
                                <Route path="newsletters" element={<Newsletters />} />
                                <Route path="*" Component={NotFound} />
                            </Routes>
                        </FallbackProvider>
                    </AppFrame>
                </BrowserRouter>
            </Authentication>
        </ThemeProvider>
    );
}

function theme() {
    return createTheme({
        palette: {
            mode: 'light',
            primary: {
                main: '#1e1e20',
            },
            secondary: {
                main: '#c51143',
                light: '#e22659',
                dark: '#890b16',
            },
            background: {
                paper: '#ffffff',
                default: '#fafafa',
            },
        },
        typography: {
            h1: {
                fontSize: 50,
            },
            h2: {
                fontSize: 40,
            },
            h3: {
                fontSize: 30,
            },
            h4: {
                fontSize: 25,
            },
            h5: {
                fontSize: 20,
            },
            h6: {
                fontSize: 18,
            },
        },
    });
}
