import React from 'react';
import { Grid, Alert } from '@mui/material';

export default function ErrorPage({ children }) {
    return (
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" sx={{ minHeight: '100vh' }}>
            <Grid item xs={3} align="center">
                <Alert variant="outlined" severity="error">
                    {children}
                </Alert>
            </Grid>
        </Grid>
    );
}
