import * as string from './string.js';

export const authUrl = 'https://auth.jmshockey.com';
export const issuer = 'https://cognito-idp.us-east-2.amazonaws.com/us-east-2_yZegkAeF5';
export const jwkUrl = `${issuer}/.well-known/jwks.json`;

export const CLAIM = {
    GROUPS: 'cognito:groups',
};

/**
 * Construct a URI to the Cognito authorization endpoint
 *
 * @returns {string}
 */
export function authorizeURI() {
    return `${authUrl}/oauth2/authorize`;
}

/**
 * Construct a URI to the Cognito token endpoint
 *
 * @returns {string}
 */
export function tokenURI() {
    return `${authUrl}/oauth2/token`;
}

/**
 * Construct a URI to the Cognito logout endpoint
 *
 * @returns {string}
 */
export function logoutURI() {
    return `${authUrl}/logout`;
}

/**
 * Construct a URI to the Cognito revoken endpoint for revoking tokens
 *
 * @returns {string}
 */
export function revokeURI() {
    return `${authUrl}/oauth2/revoke`;
}

/**
 * Construct a URL to the Cognito logout endpoint
 *
 * @param {string} clientId
 * @param {string} redirect
 * @returns {string}
 */
export function logoutURL(clientId, redirect) {
    const queryString = new URLSearchParams({
        client_id: clientId,
        logout_uri: redirect,
    });

    return `${logoutURI()}?${queryString}`;
}

/**
 * Make a request to Cognito to revoke the given token
 *
 * @param {string} clientId
 * @param {string} refreshToken
 * @returns {undefined}
 */
export async function revokeToken(clientId, refreshToken) {
    try {
        const opts = {
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                token: refreshToken,
                client_id: clientId,
            }),
        };

        await fetch( revokeURI(), opts );
    } catch (err) {
        console.warn('Issue revoking refresh token', err);
    }
}

/**
 * Check if the given token `payload` contains the given `group`
 *
 * @param {string} group
 * @param {Object} payload
 * @returns {boolean}
 */
export function tokenPayloadContainsGroup(group, payload) {
    if (!payload[CLAIM.GROUPS]) return false;
    if (!payload[CLAIM.GROUPS]?.includes(group)) return false;
    return true;
}

/**
 * Check if the given authorization `code` is a valid value
 *
 * @param {string} code
 * @returns {boolean}
 */
export function isValidAuthorizationCode(code) {
    if (!string.isUUID(code)) return false;
    return true;
}
