import React from 'react';
import { Grid, Typography } from '@mui/material';

export default function Loading() {
    return (
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" sx={{ minHeight: '25vh' }}>
            <Grid item xs={3} align="center">
                <Typography variant="h1">Page Not Found</Typography>
                <Typography>The page you are attempting to acces does not exist.</Typography>
            </Grid>
        </Grid>
    );
}
