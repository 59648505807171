/**
 * Check if the given `string` is a valid UUID
 *
 * @param {string} string
 * @returns {boolean}
 */
export function isUUID(string) {
    if (typeof string !== 'string') return false;
    return /^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/.test(string?.toLowerCase());
}

/**
 * Calculate the SHA256 hash of the given `string`.
 * Returns a promise that resolves to a string.
 *
 * @param {string} string
 * @param {boolean} hex - return hexadecimal string if TRUE
 * @returns {Promise<string>}
 */
export async function sha256(string, hex = false) {
    const encoder = new TextEncoder();
    const data = encoder.encode(string);
    const buffer = await window.crypto.subtle.digest('SHA-256', data);
    const array = new Uint8Array(buffer);
    return hex ? dec2hex(array) : String.fromCharCode.apply(null, array);
}

/**
 * Encode the given `string` and a URL-safe Base64 value.
 *
 * @param {string} string
 * @returns {string}
 */
export function base64URLEncode(string) {
    return btoa(string)
        .replace(/\+/g, '-') // replace plus symbols with hyphens
        .replace(/\//g, '_') // replace forward slashes with underscores
        .replace(/=+$/, ''); // remove trailing equal signs
}

/**
 * Decode the given base64 encoded `string` into utf8
 *
 * @param {string} string
 * @returns {string}
 */
export function base64URLDecode(string) {
    const input = string.replace(/-/g, '+').replace(/_/g, '/');
    const pad = input.length % 4;
    const padded = !pad ? input : input + new Array(5-pad).join('=');
    return atob(padded);
}

/**
 * Generate a cryptographically secure random string of `size`
 *
 * @param {integer} size
 * @returns {string}
 */
export function generateRandomString(size) {
    const array = new Uint8Array(size / 2);
    return dec2hex(window.crypto.getRandomValues(array));
}

/**
 * Convert a base10 byte array to a base16 byte array
 *
 * @param {array} array
 * @returns {array}
 */
function dec2hex(array) {
    const dec2hex = dec => ('0' + dec.toString(16)).substr(-2);
    return Array.from(array, dec2hex).join('');
}
