import * as string from './string.js';
import * as browser from './browser.js';

/**
 * Construct a URL to an OpenID Provider authorization endpoint
 *
 * @param {string} clientId
 * @param {string} authURI
 * @param {string} redirectURI
 * @param {string} codeVerifier
 * @param {Array<string>} scopes
 * @returns {string}
 */
export async function authorizeURL(clientId, authURI, redirectURI, codeVerifier, scopes = [ 'openid' ], state = {}) {
    const queryString = new URLSearchParams({
        client_id: clientId,
        redirect_uri: redirectURI,
        scope: scopes.join(' '),
        response_type: 'code',
        code_challenge_method: 'S256',
        code_challenge: await codeChallengeFromVerifier(codeVerifier),
        state: string.base64URLEncode(JSON.stringify(state)),
    });

    return `${authURI}?${queryString}`;
}

/**
 * Use the given authorization `code` (and `codeVerifier`) to retrieve tokens
 * for the given `clientId`
 *
 * @param {string} clientID
 * @param {string} tokenURI - URI where the token request will be made to
 * @param {string} code
 * @param {string} codeVerifier
 * @returns {Promise<Object>}
 * @throws {Promise<Error>}
 */
export async function fetchTokensFromAuthorizationCode(clientID, tokenURI, code, codeVerifier) {
    return await fetchTokens(clientID, tokenURI, { code, codeVerifier });
}

/**
 * use the given `refreshToken` to retrieve tokens for the given `clientId`
 *
 * @param {string} clientID
 * @param {string} tokenURI - URI where the token request will be made to
 * @param {string} refreshToken
 * @returns {Promise<Object>}
 * @throws {Promise<Error>}
 */
export async function fetchTokensFromRefreshToken(clientID, tokenURI, refreshToken) {
    return await fetchTokens(clientID, tokenURI, { refreshToken });
}

/**
 * Return the base64-urlencoded sha256 hash for the PKCE challenge
 *
 * @param {string} codeVerifier
 * @returns {string}
 */
export async function codeChallengeFromVerifier(codeVerifier) {
    return string.base64URLEncode(await string.sha256(codeVerifier));
}

/**
 * Create a code verifier string to be used as a Proof Key for Code Challenge (PKCE)
 *
 * @returns {string}
 */
export function generateCodeVerifier() {
    return string.generateRandomString(128);
}

/**
 * Fetch tokens for the given `clientID` from an OpenID Provider token endpoint
 *
 * @param {string} clientID
 * @param {string} tokenURI - URI where the token request will be made to
 * @param {Object} grantOpts
 * @returns {Promise<Object>}
 * @throws {Promise<Error>}
 */
async function fetchTokens(clientID, tokenURI, grantOpts = {}) {
    const { code, codeVerifier, refreshToken } = grantOpts;
    const body = new URLSearchParams({
        client_id: clientID,
    });

    if ((!code && !codeVerifier) && !refreshToken) {
        throw new Error('expected either a code and codeVerifier or a refreshToken');
    }

    if (code && codeVerifier) {
        body.set('grant_type', 'authorization_code');
        body.set('redirect_uri', browser.origin());
        body.set('code_verifier', codeVerifier);
        body.set('code', code);
    }

    if (refreshToken) {
        body.set('grant_type', 'refresh_token');
        body.set('refresh_token', refreshToken);
    }

    const fetchOpts = {
        method: 'POST',
        body,
        headers: {
            'content-type': 'application/x-www-form-urlencoded',
        },
    };

    const response = await fetch(tokenURI, fetchOpts);

    if (!response.ok) {
        const data = await response.json();
        console.error('token fetch failed', data);
        throw new Error('token request was not successful');
    }

    const data = await response.json();

    if (data.error) {
        console.error('token fetch failed', data);
        throw new Error('token request included an error');
    }

    return data;
}
