/**
 * Create an in-memory cache instance
 *
 * @returns {Object}
 */
export default function createCache() {
    const store = new Map();

    return {
        set: set.bind(null, store),
        has: has.bind(null, store),
        get: get.bind(null, store),
        del: del.bind(null, store),
    };
}

/**
 * Set a value in the in-memory cache instance
 *
 * @param {Map} store
 * @param {mixed} key
 * @param {mixed} value
 * @param {Object} opts
 * @returns {mixed}
 */
function set(store, key, value, opts = {}) {
    let timer, expires;
    if (opts.ttl) {
        timer = setTimeout(() => exp(store, key, value, opts.onExpire), opts.ttl);
        expires = Date.now() + opts.ttl;
    }

    store.set(key, { value, expires, timer });

    return value;
}

/**
 * Check if the given `key` exists in the in-memory cache instance
 *
 * @param {Map} store
 * @param {mixed} key
 * @returns {boolean}
 */
function has(store, key) {
    return store.has(key);
}

/**
 * Get a value out of the in-memory cache instance
 *
 * @param {Map} store
 * @param {mixed} key
 * @returns {mixed|undefined}
 */
function get(store, key) {
    const { value, expires, ...opts } = store.get(key) ?? {};

    if ( expires && expires < Date.now() ) {
        exp(store, key, value, opts.onExpire);
        return undefined;
    }

    return value;
}

/**
 * Delete a value out of the in-memory cache instance
 *
 * @param {Map} store
 * @param {mixed} key
 * @returns {mixed|undefined}
 */
function del(store, key) {
    const { timer } = store.get(key) ?? {};
    clearTimeout(timer);
    store.delete(key);
}

/**
 * Handle expiration of a key
 *
 * @param {Map} store
 * @param {mixed} key
 * @param {mixed} value
 * @param {Function} onExpire
 * @returns {undefined}
 */
function exp(store, key, value, onExpire = () => {}) {
    del(store, key);
    Promise.resolve( onExpire(key, value) )
        .catch(console.error.bind(console, 'error executing onExpire', key));
}
