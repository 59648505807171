import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import MUIListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Container from '@mui/material/Container';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SportsHockeyIcon from '@mui/icons-material/SportsHockey';
import GroupsIcon from '@mui/icons-material/Groups';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ChecklistIcon from '@mui/icons-material/Checklist';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import SportsIcon from '@mui/icons-material/Sports';

import { useLinkClickHandler } from 'react-router-dom';
import { useAuthentication } from './Authentication.js';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    '& .MuiDrawer-paper': {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: 'border-box',
        ...(!open && {
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9),
            },
        }),
    },
}));

export default function AppFrame({ children }) {
    const [open, setOpen] = React.useState(false);
    const toggleDrawer = () => setOpen(!open);

    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar position="absolute" open={open}>
                <Toolbar sx={{ pr: '24px', /* keep right padding when drawer closed */ }}>
                    <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={toggleDrawer} sx={{ marginRight: '36px', ...(open && { display: 'none' }), }} >
                        <MenuIcon />
                    </IconButton>
                    <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                        JMS Hockey Admin
                    </Typography>
                    <AccountButton />
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <Toolbar sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', px: [1], }}>
                    <IconButton onClick={toggleDrawer}>
                        <ChevronLeftIcon />
                    </IconButton>
                </Toolbar>
                <Divider />
                <List component="nav">
                    <PrimaryNav />
                    <Divider />
                    <SecondaryNav />
                </List>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, height: '100vh', overflow: 'auto', mt: 2 }}>
                <Toolbar /> { /* Ensure content does hide behind main header bar */}
                <Container maxWidth="lg">
                    {children}
                </Container>
            </Box>
        </Box>
    );
}

function ListItemButton({ to, children }) {
    return <MUIListItemButton onClick={useLinkClickHandler(to)}>{children}</MUIListItemButton>;
}

function PrimaryNav() {
    return (
        <React.Fragment>
            <ListItemButton to="/">
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
            </ListItemButton>
            <ListItemButton to="/users">
                <ListItemIcon>
                    <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Users" />
            </ListItemButton>
            <ListItemButton to="/transactions">
                <ListItemIcon>
                    <ReceiptIcon />
                </ListItemIcon>
                <ListItemText primary="Transactions" />
            </ListItemButton>
            <ListItemButton to="/surveys">
                <ListItemIcon>
                    <ChecklistIcon />
                </ListItemIcon>
                <ListItemText primary="Survey Cases" />
            </ListItemButton>
            <ListItemButton to="/games">
                <ListItemIcon>
                    <SportsHockeyIcon />
                </ListItemIcon>
                <ListItemText primary="Pickup Games" />
            </ListItemButton>
            <Divider />
            <ListItemButton to="/leagues">
                <ListItemIcon>
                    <GroupsIcon />
                </ListItemIcon>
                <ListItemText primary="Leagues" />
            </ListItemButton>
            <ListItemButton to="/leagues-games">
                <ListItemIcon>
                    <EmojiEventsIcon />
                </ListItemIcon>
                <ListItemText primary="League Games" />
            </ListItemButton>
            <ListItemButton to="/referees">
                <ListItemIcon>
                    <SportsIcon />
                </ListItemIcon>
                <ListItemText primary="Referees" />
            </ListItemButton>
        </React.Fragment>
    );
}

function SecondaryNav() {
    return (
        <React.Fragment>
            <ListItemButton to="/reports">
                <ListItemIcon>
                    <BarChartIcon />
                </ListItemIcon>
                <ListItemText primary="Reports" />
            </ListItemButton>
            <ListItemButton to="/newsletters">
                <ListItemIcon>
                    <NewspaperIcon />
                </ListItemIcon>
                <ListItemText primary="Newsletter" />
            </ListItemButton>
        </React.Fragment>
    );
}

function AccountButton() {
    const { logout } = useAuthentication();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = event => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const handleLogout = () => logout();

    return (
        <React.Fragment>
            <IconButton
                color="inherit"
                id="account-button"
                onClick={handleClick}
                aria-haspopup="true"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
            >
                <AccountCircleIcon />
            </IconButton>
            <Menu id="account-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{ 'aria-labelledby': 'account-button' }}
            >
                <MenuItem onClick={handleLogout}>Log Out</MenuItem>
            </Menu>
        </React.Fragment>
    );
}
