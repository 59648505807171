import React from 'react';
import { useState, useEffect, createContext, useContext } from 'react';
import Loading from './Loading.js';
import ErrorPage from './ErrorPage.js';
import * as auth from '../lib/auth.js';

const AuthContext = createContext();

/**
 * React component that initializes authentication and an auth context that can
 * be used by children.
 *
 * @param {Object} props
 * @returns {Object}
 */
export default function Authentication({ children }) {
    const [hasAuthenticated, setAuthenticated] = useState(false);
    const [notAuthorized, setNotAuthorized] = useState(null);
    const [tokens, setTokens] = useState(null);

    useEffect(() => {
        auth.checkAuthentication(setTokens, setNotAuthorized)
            .then(setAuthenticated)
            .catch(() => setNotAuthorized(true));
    }, []);

    if (notAuthorized) {
        return <ErrorPage>You are not authorized!</ErrorPage>;
    }

    if (!hasAuthenticated || !tokens) {
        return <Loading label="Authenticating User" />;
    }

    return (
        <AuthContext.Provider value={tokens}>
            {children}
        </AuthContext.Provider>
    );
}

/**
 * Custom React hook for access authentication data from the auth context.
 *
 * @returns {Object}
 */
export function useAuthentication() {
    const tokens = useContext(AuthContext);
    return { ...tokens, logout: auth.logout };
}
