/**
 * Get a cookie value by name
 *
 * @param {string} name - name of cookie to retrieve
 * @returns {string}
 */
export function getCookie(name) {
    return document.cookie
        .split(';')
        .find(row => row.startsWith(`${name}=`))
        ?.split('=')
        .at(1);
}

/**
 * Set a cookie value and expiration by name
 *
 * @param {string} name
 * @param {string} value
 * @param {integer} expire
 * @returns {undefined}
 */
export function setCookie(name, value, expire) {
    if (typeof name !== 'string' && !(name instanceof String)) {
        throw new TypeError('name must be string');
    }

    if (typeof value !== 'string' && !(value instanceof String)) {
        throw new TypeError('value must be string');
    }

    if (Number.isNaN(parseInt(expire)) || parseInt(expire) !== expire || expire < 0) {
        throw new TypeError('expire must be positive integer');
    }

    let cookie = `${name}=${value}; path=/; max-age=${expire}; samesite=strict;`;
    if (/^https/.test(window.location.protocol)) cookie += ' secure;';
    document.cookie = cookie;
}

/**
 * Remove a cookie by name.
 *
 * NOTE: we remove a cookie by setting it to an empty string and then an
 * immediate expire time. There is no explict way to remove a cookie.
 *
 * @param {string} name
 * @returns {undefined}
 */
export function removeCookie(name) {
    setCookie(name, '', 1);
}

/**
 * Get the origin of the current browser location
 *
 * @returns {string}
 */
export function origin() {
    return window.location.origin;
}

/**
 * Get a query string parameter out of the current browser location
 *
 * @param {string} name
 * @param {string|null} defaultValue
 * @returns {string|null} - return value if found, otherwise `defaultValue`
 */
export function getQueryParam(name, defaultValue = null) {
    const queryParameters = new URLSearchParams(window.location.search);
    return queryParameters.get(name) ?? defaultValue;
}

/**
 * Remove a query string parameter from the current browser location by name,
 * if it exists.
 *
 * @param {string} name
 * @returns {undefined}
 */
export function removeQueryParam(name) {
    const queryParameters = new URLSearchParams(window.location.search);

    if (!queryParameters.has(name)) return;

    queryParameters.delete(name);

    const state = history.state;
    const title = document.title;
    const search = queryParameters.toString();
    const url = window.location.origin + window.location.pathname + (search ? '?' + queryParameters : '');
    history.pushState(state, title, url);
}

/**
 * Set the URL path for the browser location. This will trigger routing changes.
 *
 * @param {string} path
 * @returns {undefined}
 */
export function setPath(path) {
    if (path === window.location.pathname) {
        return;
    }

    const state = history.state;
    const title = document.title;
    const search = window.location.search;
    const url = window.location.origin + path + (search ? `?${search}` : '');
    history.pushState(state, title, url);
}
